import React from 'react';
import { Checkbox, Image, Row, Space } from 'antd';
import { ArrowDownOutlined, ArrowUpOutlined, DeleteOutlined } from "@ant-design/icons";
import styled from 'styled-components';

import ImagePlaceholder from '../../assets/images/preview_image.jpeg';
import theme from '../../assets/styles/theme';
import { toFixed } from '../../util';
import { getLabel } from '../Copywriting/PrintRowsDialog';

type Props = {
  columnNum?: number;
  columns: StringKAnyVPair[];
  data: StringKAnyVPair[];
  dataType: number;
  isInDrawer?: boolean;
  onItemClick?: Function;
  onSelectChange?: Function;
  selectable?: boolean;
  selectedIdList?: string[];
  setData?: Function;
  sortDraggable?: boolean;
  sortDroppable?: boolean;
};

type GridWrapperProps = {
  colNum?: number;
};

const gridColumnWidth = (colNum?: number) => {
  let ret = 20;

  if (typeof colNum === 'number' && colNum > 0) {
    ret = parseFloat((100 / colNum).toFixed(2));
  }

  return ret;
};

const GridWrapper = styled.div<GridWrapperProps>`
  display: grid;
  grid-template-columns: repeat(auto-fill, ${props => gridColumnWidth(props.colNum)}%);
  transition: all .3s ease-in;

  & .ant-image {
    aspect-ratio: 183/275;
    background-color: #ECECEC;
    width: 100%;
  }

  & .ant-image-img {
    height: 100%;
    object-fit: contain;
  }

  & .bottom-toolbar {
    height: auto !important;
    padding: 2px 6px;
  }

  & .danger-btn {
    color: ${theme['@danger-color']};
  }

  & .danger-btn,
  & .toolbar-btn {
    border-radius: 4px;
  }

  & .danger-btn .anticon,
  & .toolbar-btn .anticon {
    margin: 0 2px;
  }

  & .danger-btn:hover,
  & .toolbar-btn:hover {
    background-color: #ECECEC;
    cursor: pointer;
  }

  & .product-item-ctn {
    border: 1px solid #D9D9D9;
    margin: 2px;
  }

  & .product-item-ctn:hover {
    border-color: ${theme['@secondary-btn-bg']};
  }

  & .product-item-wrapper {
    border: 1px solid transparent;
    padding: 2px;
  }

  & .text-row {
    font-family: system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue","Noto Sans","Liberation Sans",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
    //font-size: 8pt;
    line-height: 1.3em;
    margin: 0;
    overflow: hidden;
    padding: 0 6px;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

const ProductGridView = (props: Props) => {
  const { useState } = React;
  const [dragItem, setDragItem] = useState<StringKAnyVPair>({});
  const [dropItem, setDropItem] = useState<StringKAnyVPair>({});
  const [inited, setInited] = useState(false);
  const wrapperRef = React.useRef<any>(null);

  const getItemWrapper = (ele: any) => {
    let dom = ele.parentNode;
    let ret = null;

    while (dom.tagName.toLowerCase() !== 'body') {
      if (dom.getAttribute('class') === 'product-item-wrapper') {
        ret = dom
        break;
      }

      dom = dom.parentNode;
    }

    return ret;
  };

  const isItemChecked = (item: StringKAnyVPair) => {
    let ret = false;

    if (props.selectedIdList) {
      ret = props.selectedIdList.indexOf(item.ProductId) > -1;
    }

    return ret;
  };

  const onClickItemPicture = (item: StringKAnyVPair) => {
    if (props.onItemClick) props.onItemClick(item);
  };

  const onDragSortItemEnd = (evt: any, item: StringKAnyVPair) => {
    if (props.sortDroppable) {
      if (dropItem.item && dragItem) {
        const offset = dropItem.midline < evt.pageY ? 1 : 0;
        //console.log('drop --', evt.pageY, dropItem.midline);
        moveItem(dragItem, dropItem.item, offset);
        setDropItem({});
      }
    }

    setDragItem({});
  };

  const onDragSortItemOver = (evt: any, item: StringKAnyVPair) => {
    if (props.sortDroppable) {
      if (dragItem.ProductId && dragItem.ProductId !== item.ProductId) {
        const dom = getItemWrapper(evt.target);

        if (dom) {
          const rect = dom.getBoundingClientRect();
          const midline = rect.top + rect.height / 2;
          const timestamp = (new Date()).getTime();

          if (wrapperRef.current) {
            const box = wrapperRef.current.parentNode;
            const br = box.getBoundingClientRect();

            console.log(box, evt.pageX, 'y->', evt.pageY, '>', br.top, br.top + br.height);
            //console.log(dom.offsetHeigt / 2);
          }

          setDropItem({ item, midline, timestamp });
          evt.preventDefault();
        }
      }
    }
  };

  const onDragSortItemStart = (item: StringKAnyVPair) => {
    if (props.sortDraggable) {
      setDragItem(item);
    }
  };

  const onSelectionChange = (item: StringKAnyVPair, checked: boolean) => {
    //console.log('on ch', item, checked);
    if (props.onSelectChange) props.onSelectChange(item, checked);
  };

  const moveItem = (
    src: StringKAnyVPair,
    target: StringKAnyVPair,
    offset: number,
  ) => {
    const data = [ ...props.data ];

    for (let i = 0; i < data.length; i++) {
      if (data[i].ProductId === src.ProductId) {
        data.splice(i, 1);
        break;
      }
    }

    for (let i = 0; i < data.length; i++) {
      if (data[i].ProductId === target.ProductId) {
        const idx = i + offset;

        data.splice(idx, 0, src);
        break;
      }
    }

    if (props.setData) props.setData(data);
  };

  const renderItems = (items: StringKAnyVPair[]) => {
    console.log(items, '<--');

    return items.map((item, i) => (
      <div
        className="product-item-wrapper"
        key={`p-${item.ProductId}-${i}`}
      >
        <div
          className="product-item-ctn"
          onDragOver={evt => onDragSortItemOver(evt, item)}
        >
          <div
            draggable={props.sortDraggable}
            onDragEnd={evt => onDragSortItemEnd(evt, item)}
            onDragStart={() => onDragSortItemStart(item)}
          >
            <Image
              fallback={ImagePlaceholder}
              onClick={() => onClickItemPicture(item)}
              preview={false}
              src={item.mediaURL}
              style={{ cursor: props.onItemClick ? 'pointer' : undefined }}
            />
            {renderLabel(item)}
          </div>
          {props.isInDrawer && <Row className="bottom-toolbar" justify="space-between">
            <Space>
              <span className="toolbar-btn" onClick={() => sortItem(item, -1)}>
                <ArrowUpOutlined />
              </span>
              <span className="toolbar-btn" onClick={() => sortItem(item, 1)}>
                <ArrowDownOutlined />
              </span>
            </Space>
            <span className="danger-btn" onClick={() => onSelectionChange(item, false)}>
              <DeleteOutlined />
            </span>
          </Row>}
          {props.selectable && <div className="bottom-toolbar">
            <Checkbox
              checked={isItemChecked(item)}
              onChange={evt => onSelectionChange(item, evt.target.checked)}
            />
          </div>}
        </div>
      </div>
    ));
  };

  const renderLabel = (e: StringKAnyVPair) => {
    return (
      <div>
        <div className="text-row">
          <span className="bold-label">{getLabel(e, 'productName', props.dataType, props.columns)}</span>
        </div>
        <div className="text-row">
          <span className="">Style #: {e.styleCode}</span>
        </div>
        {!props.isInDrawer && <>
        <div className="text-row">
          <span className="">Season: {getLabel(e, 'season', props.dataType, props.columns)}</span>
        </div>
        <div className="text-row">
          <span className="">Color: {getLabel(e, 'color', props.dataType, props.columns)}</span>
        </div>
        <div className="text-row">
          <span className="">Size: {getLabel(e, 'size', props.dataType, props.columns)}</span>
        </div>
        <div className="text-row">
          <span className="">M.S.R.P: {toFixed(getLabel(e, 'msrp', props.dataType, props.columns))}</span>
        </div>
        <div className="text-row">
          <span className="">Wholesale: {toFixed(getLabel(e, 'wholesale', props.dataType, props.columns))}</span>
        </div>
        <div className="text-row">
          <span className="">ATS: {getLabel(e, 'atsQuantity', props.dataType, props.columns)}</span>
        </div>
        </>}
      </div>
    );
  };

  const sortItem = (item: StringKAnyVPair, step: number) => {
    const data = [ ...props.data ];
    let idx = -1;

    for (let i = 0; i < data.length; i++) {
      if (data[i].ProductId === item.ProductId) {
        idx = i;
        break;
      }
    }

    if (idx > -1 && idx + step > -1 && idx + step < data.length) {
      if (props.setData) {
        data.splice(idx, 1);
        data.splice(idx + step, 0, item);

        if (props.setData) props.setData(data);
      }
    }
  };

  React.useEffect(() => {
    if (!inited) {
      setInited(true);
    }
  }, [inited]);

  return (
    <GridWrapper colNum={props.columnNum} ref={wrapperRef}>
      {renderItems(props.data)}
    </GridWrapper>
  );
};

export default ProductGridView;
