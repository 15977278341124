import React, { useCallback, useEffect, useMemo, useState } from "react";
import axios from "axios";
import {
  LinkOutlined,
  PrinterOutlined,
  AppstoreOutlined,
  SearchOutlined,
  ShoppingCartOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons";
// import NumberFilter from '@inovua/reactdatagrid-community/NumberFilter';
import {
  Badge,
  Button,
  Col,
  Drawer,
  Image,
  Input,
  Pagination,
  Radio,
  Row,
  Select,
  Space,
  message,
  Card,
  notification,
  Tag,
} from "antd";
import { Link } from "react-router-dom";
import styled from "styled-components";

import ImagePlaceholder from '../../assets/images/preview_image.jpeg';
import ContentLayout from "../../components/ContentLayout";
import Heading from "../../components/common/Heading";
import Loading from "../../components/common/Loading";
import ScreenMask from "../../components/common/ScreenMask";
import SiteContent from "../../components/SiteContent";
import Spacer from "../../components/common/Spacer";
import { DataGrid } from "../../components/common/datagrid/DataGrid2";
import { FormLabel, StyleInputWrapper } from "../../components/common/styledComponents";
import SearchTips from "../../components/common/SearchTips";
import ERPLinkTableCell from  '../../components/common/ERPLinkTableCell'
import TableFilter from '../../components/common/TableFilter'
import {
  GRID_FILTER11,
  GRID_FILTER12,
  GRID_FILTER13,
  GRID_FILTER21,
  filterTypes,
} from "../../components/common/datagrid/Filter";
import {
  ATTRIBUTE_DATA_TYPE_IMAGEURL,
  ATTRIBUTE_DATA_TYPE_VIRTUAL,
  DEFAULT_ERR_MSG_DISPLAY_DURATION,
  LOADING_ICON_SIZE1,
  CODE_OPTION_SKU_LIST,
  CODE_OPTION_COLOR_LIST,
  CODE_OPTION_STYLE_LIST,
  CODE_OPTION_UPC_LIST,
  SEARCH_PRODUCT_PARAMS,
} from "../../constants/config";
import { getProfilesState } from '../../redux/utils';
import { fetchProfileSettings } from '../../services/channels';
import Products, {
  fetchAlternateList,
  fetchSimpleProductGroup,
  fetchSimpleProductList2,
  fetchSimpleProductPartialData,
  // fetchProductCopywrting,
  fetchProductCopywrtingLinesheet,
  fetchProductInventoryAts,
} from '../../services/products';
import {
  fetchContentSourceType,
  fetchCopywritingelements,
  saveCopywritingSingleElement,
  getControlTowerOrigin,
} from '../../services/copywriting';
//import ClassificationSelector from '../ListProducts/ClassificationSelector';
import ProductDetailDialog, { getRealProductId } from '../ListProducts/ProductDetailDialog';
import GroupDetail from '../ListProducts/GroupDetail';
import LabelsSelector from '../ListProducts/LabelsSelector';
// import SortSelector from '../ListProducts/SortSelector';
import ChannelControlFlagSelector from '../ListProducts/ChannelControlFlagSelector';
import SetValueCell from '../Channel/setValueCell';
import EditImageDialog from '../DetailProduct/EditImageDialog';
import SimpleSelectorModal from '../DetailProduct/BasicAttrEditor/SimpleSelectorModal';
import TextEditor from '../DetailProduct/BasicAttrEditor/TextEditor';
import { SOURCE_TYPE_ERP } from '../DetailProduct/CopywritingSteps';
import { loadStyleVariations } from '../DetailProduct/helper';
import {
  getOperationStatusDesc,
  getOperationStatusOptions,
} from '../DetailProduct/BasicAttrEditor';
import ProductGridView from './ProductGridView';
import {
  checkStringType,
  getProfileSettingValue,
  //isApp02,
  //isDevEnv,
} from '../../util';

// import ExportModal from './ExportModal';
// import ExportVendorModal from './ExportVendorModal';
// import BlukModal from './BlukModal';
// import BlukPullModal from './BlukPullModal';
// import BulkEditDialog, { BULK_RO_COLUMNS, cellEditor } from './BulkEditDialog';
// import BulkPushSteps from './BulkPushSteps';
// import BulkCopySteps from './BulkCopySteps';
// import ImportModal from './ImportModal';
import PrintRowsDialog from '../Copywriting//PrintRowsDialog';
import SearchFilterPanel from './SearchFilterPanel';
// import ProductsDrawer from './ViewSelectedDrawer';

const CancelToken = axios.CancelToken;
let cancel: any = undefined;

type SKULinkProps = {
  product: Entities.ProductProfile;
  target?: string;
};

const SKULink = (props: SKULinkProps) => {
  const { product, target = "_self" } = props;
  const pids = product.ProductId.split("/");
  const productId = pids[pids.length - 1] || "-";

  return (
    <Link target={target} to={`/product-detail/${productId}`}>
      <LinkOutlined />
      &nbsp;
      {product.SKU}
    </Link>
  );
};

const DrawerContainer = styled(Row)`
  height: 100%;
  position: relative;

  & .width-dragger {
    background-color: #A6E4FF;
    height: 100%;
    width: 4px;
  }

  & .width-dragger:hover {
    background-color: #138496;
    cursor: col-resize;
  }

  & .width-dragger::after {
    background-color: #138496;
    content: "";
    height: 60px;
    //left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    width: 4px;
  }
`;

const GridDrawerWrapper = styled.div`
  height: 100%;
  overflow-y: auto;
  width: calc(100% - 4px);
`;

const GridViewContainer = styled.div`
  height: 100%;
  position: relative;

  & .grid-bottom-toolbar {
    height: 40px;
    position: absolute;
    top: calc(100% - 39px);
    width: 100%;
  }

  & .grid-ctn {
    height: calc(100% - 40px);
    overflow-y: auto;
    position: absolute;
    width: 100%;
  }
`;

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
`;

// const SectionTitle = styled.h2`
//   font-weight: bold;
//   margin-bottom: 0;
// `;

const PageWrapper = styled(SiteContent)`
  & .selected-product-drawer .ant-badge-count,
  & .shopping-cart-btn .ant-badge-count {
    background-color: #FAAD14;
  }

  & .shopping-cart-btn .ant-badge-count {
    margin-top: -5px;
  }

  & .selected-product-drawer .ant-drawer-close {
    padding-left: 4px;
    padding-right: 12px;
    padding-top: 16px;
  }

  & .selected-product-drawer .ant-drawer-header {
    padding: 13px 12px;
  }

  & .selected-product-drawer .ant-drawer-header .ant-badge {
    margin-top: -6px;
  }

  & .shopping-cart-btn {
    font-size: 18px;
    padding: 2px 6px 4px 6px;
  }
`;

const SearchCol = styled(Col)`
  width: 100%;

  & .ant-input:read-only {
    background-color: #dcdcdc;
  }

  & .ant-input-affix-wrapper-readonly {
    background-color: #dcdcdc;
  }

  & .ant-radio-button-wrapper {
    padding: 0 10px;
  }

  & .ant-radio-wrapper {
    margin-right: 6px;
  }

  & .action-btn-wrap {
    /*display: none;*/
  }

  & .ant-input-group-addon {
    border: solid 1px #d9d9d9 !important;
  }

  & .channel-ctrl-flag-wrapper,
  & .classification-wrapper,
  & .labels-wrapper,
  & .title-wrapper {
    display: inline-block;
  }

  & .channel-ctrl-flag-wrapper {
    width: 383px;
  }

  & .classification-wrapper {
    width: 390px;
  }

  & .item-spacer {
    display: inline-block;
    width: 12px;
  }

  & .labels-wrapper {
    width: 346px;
  }

  & .title-wrapper {
    width: 270px;
  }

  & .title-wrapper .brand-input {
    width: 208px;
  }

  & .channel-ctrl-flag-wrapper .ant-input,
  & .classification-wrapper .ant-input,
  & .labels-wrapper .ant-input {
  }

  & .channel-ctrl-flag-wrapper .ant-select-selector,
  & .classification-wrapper .ant-select-selector,
  & .labels-wrapper .ant-select-selector {
  }

  & .display-category-field {
    min-height: 32px;
  }

  & .field-label {
    display: inline-block;
    padding-left: 0;
    padding-right: 8px;
    font-weight: 550;
  }

  & .field-label:after {
    content: ": ";
  }

  & .loading-wrapper {
    display: "inline-block";
    height: 30px;
    margin: 0;
    padding: 1;
  }

  & .search-btn-row {
    /*width: 768px;*/
    width: 100%;
  }

  & .search-element-area {
    justify-content: space-between;
    width: 100%;
  }

  & .status-selector {
    width: 140px;
  }

  @media screen and (max-width: 1199px) {
    & .action-btn-wrap {
      display: unset;
      padding-top: 8px;
    }

    & .search-element-area {
      width: calc(100vw - 300px);
    }
  }

  @media (min-width: 1200px) and (max-width: 1430px) {
    & .display-category-field {
      width: 430px;
    }
  }

  @media (min-width: 1230px) and (max-width: 1275px) {
    & .search-btn-row {
      /*width: 820px;*/
      width: 100%;
    }

    & .status-selector {
      width: 170px;
    }
  }

  @media (min-width: 1276px) and (max-width: 1335px) {
    & .search-btn-row {
      /*width: 868px;*/
      width: 100%;
    }

    & .status-selector {
      width: 230px;
    }
  }

  @media (min-width: 1336px) and (max-width: 1436px) {
    & .search-btn-row {
      /*width: 928px;*/
      width: 100%;
    }

    & .status-selector {
      width: 300px;
    }
  }

  @media (min-width: 1437px) and (max-width: 1560px) {
    & .search-btn-row {
      /*width: 1028px;*/
      width: 100%;
    }

    & .status-selector {
      width: 390px;
    }
  }

  @media (min-width: 1561px) {
    & .search-btn-row {
      /*width: 1150px;*/
      width: 100%;
    }

    & .status-selector {
      width: 460px;
    }
  }
`;

// const ProductDetailDialogBodyWrapper = styled.div`
//   /*max-height: calc(100vh - 180px);*/
//   height: calc(100vh - 260px);
//   overflow-y: auto;

//   &.fullscreen-mode {
//     height: calc(100vh - 168px);
//   }
// `;

// const ExportBar = styled.div`
//   display: flex;
//   flex-direction: column;
//   justify-content: end;
//   align-items: center;
//   height: 100%;
// `;

export const isCodeListType = (typ: number) => {
  const cs = [
    CODE_OPTION_SKU_LIST,
    CODE_OPTION_COLOR_LIST,
    CODE_OPTION_STYLE_LIST,
    CODE_OPTION_UPC_LIST,
  ];

  return cs.indexOf(typ) > -1;
};

export const columns = [
  {
    name: "mediaURL",
    header: "Image",
    defaultFlex: 1,
    defaultLocked: true,
    minWidth: 100,
    maxWidth: 100,
    render({ value, data }: { value: string; data: Entities.ProductProfile }) {
      const src = value || "https://via.placeholder.com/300";
      //const isTreeNode = 'nodes' in data;
      //const isTreeNode = searchGroupMode;
      const isTreeNode = true;

      return (
        <ImageContainer
          key={data.ProductId}
          className={isTreeNode ? "image-tree-ctn" : ""}
        >
          <Image width={28} height={28} src={src} />
        </ImageContainer>
      );
    },
  },
  {
    name: "VariationParentSKU",
    header: "Style",
    defaultFlex: 1,
    defaultLocked: true,
    minWidth: 120,
    render({ data }: { data: any }) {
      if (data && typeof data === "object") {
        // return data.styleCode || data.VariationParentSKU;
        return <ERPLinkTableCell type={1} value={data.styleCode || data.VariationParentSKU}  />
      }

      return "";
    },
  },
  {
    //name: 'ProductId',
    name: "subStyleCode",
    header: "Substyle",
    defaultFlex: 1,
    defaultLocked: true,
    minWidth: 180,
    render({ data }: { data: any }) {
      if (data && typeof data === "object") {
        // return data.subStyleCode || "";
        return <ERPLinkTableCell type={2} value={ data.subStyleCode || ""} />
      }

      return "";
    },
  },
  {
    name: "SKU",
    header: "SKU",
    defaultFlex: 1,
    defaultLocked: true,
    minWidth: 200,
    // sort: (a: any, b: any) => false,
    render({ data }: { data: Entities.ProductProfile }) {
      return <SKULink product={data} />;
    },
  },
  {
    name: "ProductStatus",
    header: "Status",
    defaultFlex: 1,
    minWidth: 100,
    render(row: any) {
      const { data } = row;

      return getOperationStatusDesc(data.ProductStatus, true);
    },
  },
  {
    name: "atsQuantity",
    header: "ATS",
    textAlign: "end",
    width: 80,
  },
];

let profileSettings: StringKAnyVPair[] = [];

export const GROUP_OPTION_COLOR = 1;
export const GROUP_OPTION_STYLE = 2;
export const GROUP_OPTION_PRODUCT = 4;

const PageContent: React.FC = () => {
  const { Option } = Select;
  const CODE_OPTION_STYLE_EQUALS = 1;
  const CODE_OPTION_STYLE_CONTAINS = 2;
  const CODE_OPTION_SKU_EQUALS = 3;
  const CODE_OPTION_SKU_CONTAINS = 4;
  const CODE_OPTION_COLOR_EQUALS = 5;
  const CODE_OPTION_COLOR_CONTAINS = 6;
  const CODE_OPTION_UPC_EQUALS = 7;
  const CODE_OPTION_SKU_LIST = 8;
  const CODE_OPTION_STYLE_LIST = 9;
  const CODE_OPTION_COLOR_LIST = 10;
  const CODE_OPTION_UPC_LIST = 11;
  const DEFAULT_GRID_LIMIT = 20;
  const GROUP_OPTION_NO_GROUP = 3;
  const MAX_BADGE_COUNT = 10000;
  const OPERATION_CONTAIN = 2;
  const OPERATION_EQUALS = 1;
  const OPERATION_LIST = 3;
  const SELECTED_PRODUCT_DRAWER_DEFAULT_WIDTH = 140;
  const {defaultSearchCodeType, defaultSearchGroup} = localStorage.getItem(SEARCH_PRODUCT_PARAMS) ? JSON.parse(localStorage.getItem(SEARCH_PRODUCT_PARAMS) ||'') : {
    defaultSearchCodeType: CODE_OPTION_SKU_CONTAINS,
    defaultSearchGroup: GROUP_OPTION_PRODUCT,
  }

  // const [extLoading, setExtLoading] = useState<boolean>(false);
  const [filteredTempData, setFilteredTempData] = useState<any[]>([]);
  const [filteredData, setFilteredData] = useState<any[]>([]);
  const [currentProduct, setCurrentProduct] = useState<StringKAnyVPair>({});
  const [stateColumns, setStateColumns] = useState<any>(columns);
  const [detailVisible, setDetailVisible] = useState(false);
  const [editImageDialogVisible, setEditImageDialogVisible] = useState(false);
  const [editSelectorDialogVisible, setEditSelectorDialogVisible] = useState(
    false
  );
  const [eleAttrs, setEleAttrs] = useState<StringKAnyVPair[]>([]);
  const [extraAgeGroup, setExtraAgeGroup] = useState<string[]>([]);
  const [extraAlternateCode, setExtraAlternateCode] = useState<string[]>([]);
  const [extraAlternateOptions, setExtraAlternateOptions] = useState<StringKAnyVPair[]>([]);
  const [extraBundleType, setExtraBundleType] = useState<number>();
  const [extraCategoryCode, setExtraCategoryCode] = useState<string[]>([]);
  const [extraClassCode, setExtraClassCode] = useState<string[]>([]);
  const [extraCountryOfOrigin, setExtraCountryOfOrigin] = useState<string[]>([]);
  const [extraDepartmentCode, setExtraDepartmentCode] = useState<string[]>([]);
  const [extraDivisionCode, setExtraDivisionCode] = useState<string[]>([]);
  const [extraGender, setExtraGender] = useState<string[]>([]);
  const [extraGroupCode, setExtraGroupCode] = useState<string[]>([]);
  const [extraManufacturer, setExtraManufacturer] = useState<string[]>([]);
  const [extraModel, setExtraModel] = useState<string[]>([]);
  const [extraProductYear, setExtraProductYear] = useState('');
  const [extraRemark, setExtraRemark] = useState('');
  const [extraSubClassCode, setExtraSubClassCode] = useState<string[]>([]);
  const [extraSubGroupCode, setExtraSubGroupCode] = useState<string[]>([]);
  const [gridRef, setGridRef] = useState<any>(null);
  const [initDataReady, setInitDataReady] = useState(false);
  const [inited, setInited] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [pageSkip, setPageSkip] = useState(0);
  const [pageTop, setPageTop] = useState(DEFAULT_GRID_LIMIT);
  //const { data = initialProductListing, isFetching } = useSimpleProductsQuery();
  const [printRowsDialogVisible, setPrintRowsDialogVisible] = useState(false);
  const [searchBegun, setSearchBegun] = useState(false);
  const [searchCCFs, setSearchCCFs] = useState<any[]>([]);
  const [searchCFs, setSearchCFs] = useState<any[]>([]);
  const [searchCode, setSearchCode] = useState("");
  const [searchCodeType, setSearchCodeType] = useState(
    defaultSearchCodeType
  );
  const [searchECCFs, setSearchECCFs] = useState<any[]>([]);
  // eslint-disable-next-line
  const [searchMore, setSearchMore] = useState(false);
  const [searchMultiCode, setSearchMultiCode] = useState("");
  const [searchTreeMode, setSearchTreeMode] = useState(false);
  const [searchGroup, setSearchGroup] = useState(defaultSearchGroup);
  const [searchGroupMode, setSearchGroupMode] = useState(false);
  const [searchBrand, setSearchBrand] = useState<string>("");
  const [searchLabels, setSearchLabels] = useState<any[]>([]);
  const [searchSorter, setSearchSorter] = useState<string>("SKU ASC");
  const [searchStatus, setSearchStatus] = useState<number[]>([1]);
  const [searchTitle, setSearchTitle] = useState("");
  const [searchGroups, setSearchGroups] = useState<number[]>([]);
  const [searchGroupsStr, setSearchGroupsStr] = useState<string[]>([]);
  const [searchTags, setSearchTags] = useState<string[]>([]);
  const [selectedProductDrawerVisible, setSelectedProductDrawerVisible] = useState(false);
  const [selectedProductDrawerWidth, setSelectedProductDrawerWidth] = useState(SELECTED_PRODUCT_DRAWER_DEFAULT_WIDTH + 20);
  // const [searchTagsStr, setSearchTagsStr] = useState<string>('');
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  // eslint-disable-next-line
  const [styleVariation, setStyleVariation] = useState<StringKAnyVPair>({});
  const [totalCount, setTotalCount] = useState(0);
  // const [isMapped, setIsMapped] = useState<boolean>(false);
  // const [templateLoading, setTemplateLoading] = useState<boolean>(true);
  const [selected, setSelected] = useState<any>({});
  // const [templateInfo, setTemplateInfo] = React.useState<any>();
  const [viewMode, setViewMode] = useState('list');
  const [groupList, setGroupList] = useState<any[]>([]);
  const searchMoreStyle = useMemo(() => {
    return {
      display: searchMore ? "" : "none",
    };
  }, [searchMore]);
  const [productListStyle, setProductListStyle] = useState<string>();
  const [currentValue, setCurrentValue] = React.useState<any>();
  const [valueModalVisible, setValueModalVisible] = React.useState(false);

  const [channelFlagVersion, setChannelFlagVersion] = useState(0);
  const [excludeChannelFlagVersion, setExcludeChannelFlagVersion] = useState(0);
  const [labelVersion, setLabelVersion] = useState(0);
  // const [groupVersion, setGroupVersion] = useState(0);
  // const [tagVersion, setTagVersion] = useState(0);
  const [searchBrandVersion, setSearchBrandVersion] = useState(0);
  const [searchTitleVersion, setSearchTitleVersion] = useState(0);
  const [filterPanelVisible, setFilterPanelVisible] = useState(false);
  const [filterPanelOutline, setFilterPanelOutline] = useState<StringKAnyVPair>(
    {}
  );
  const [ccfList, setCcfList] = useState<StringKAnyVPair[]>([]);
  const [labelList, setLabelList] = useState<StringKAnyVPair[]>([]);
  const [searchTagVersion, setSearchTagVersion] = useState(0);
  const [searchGroupsVersion, setSearchGroupsVersion] = useState(0);
  const [dataInited, setDataInited] = useState(false);
  // eslint-disable-next-line
  const [originKeyValue, setOriginKeyValue] = useState<StringKAnyVPair>({});
  const [overwriteChild, setOverwriteChild] = useState(false);
  const codeInputRef = React.useRef<any>(null);
  const codeListInputRef = React.useRef<any>(null);
  const filterHiddenTimer = React.useRef<any>(0);
  const filterState = React.useRef<any>(null);

  const loadProfileSetting = useCallback(async () => {
    const { profiles, selectedIndex } = getProfilesState();
    const profileNum = profiles[selectedIndex].ProfileNum;
    let ps1: StringKAnyVPair | undefined = profileSettings.filter(e => profileNum > 0 && e.ProfileNum === profileNum && e.SettingCode === 'ProductOverwriteChildrenValue')[0];

    if ( !ps1) {
      try {
        const confs = await fetchProfileSettings();
        //console.log('setting', profileNum, confs);
        if (Array.isArray(confs)) {
          profileSettings = confs;
          ps1 = confs.filter(e => profileNum > 0 && e.ProfileNum === profileNum && e.SettingCode === 'ProductOverwriteChildrenValue')[0];
        }
      } catch (e) {
        notification.error({
          message: `Fetch profile settings error: ${e}`,
          duration: DEFAULT_ERR_MSG_DISPLAY_DURATION,
        });
      } 
    }

    if (ps1) {
      const allowOverWrite = parseInt(ps1.SettingValue) || 0;
      setOverwriteChild(!!allowOverWrite);
    }
  },[]);

  useEffect(() => {
    loadProfileSetting();
  }, [loadProfileSetting]);

  useEffect(()=>{
    localStorage.setItem(SEARCH_PRODUCT_PARAMS, JSON.stringify({
      defaultSearchCodeType: searchCodeType,
      defaultSearchGroup: searchGroup,
    }))
  },[searchCodeType, searchGroup])

  const fetchLabels = async () => {
    try {
      const res = await Products.getLabels({
        $count: true,
        $top: 0,
      });

      if (
        res &&
        typeof res === "object" &&
        Array.isArray(res.LabelList) &&
        res.LabelList.length > 0
      ) {
        setLabelList(res.LabelList);
      }
    } catch (e) {
      notification.error({
        message: `Fetch Channel Control Flags error: ${e}`,
        duration: DEFAULT_ERR_MSG_DISPLAY_DURATION,
      });
    }
  };

  // eslint-disable-next-line
  const addSelectedRows = (sels: StringKAnyVPair[]) => {
    const ns: StringKAnyVPair[] = [];
    const ids = selectedRows.map(e => e.ProductId);
    let rows: StringKAnyVPair[] = [];

    sels.forEach(e => {
      if (ids.indexOf(e.ProductId) < 0) {
        ns.push(e);
      }
    });
    rows = [ ...selectedRows, ...ns ];
    setSelectedRows(rows);

    return rows;
  };

  const addonSearchButton = () => {
    return (
      <SearchOutlined
        onClick={() => {
          handleSearchProducts();
          setFilterPanelVisible(false);
        }}
      />
    );
  };

  const clearChannelFlags = () => {
    setSearchCCFs([]);
    setChannelFlagVersion(channelFlagVersion + 1);
  };

  const clearExcludeChannelFlags = () => {
    setSearchECCFs([]);
    setExcludeChannelFlagVersion(excludeChannelFlagVersion + 1);
  };

  const clearExtraFilters = () => {
    clearExcludeChannelFlags();
    setExtraAgeGroup([]);
    setExtraAlternateCode([]);
    setExtraBundleType(undefined);
    setExtraCategoryCode([]);
    setExtraClassCode([]);
    setExtraCountryOfOrigin([]);
    setExtraDepartmentCode([]);
    setExtraDivisionCode([]);
    setExtraGender([]);
    setExtraGroupCode([]);
    setExtraManufacturer([]);
    setExtraModel([]);
    setExtraProductYear('');
    setExtraRemark('');
    setExtraSubClassCode([]);
    setExtraSubGroupCode([]);
  };

  const clearLabels = () => {
    setSearchLabels([]);
    setLabelVersion(labelVersion + 1);
  };

  // const clearGroup = () => {
  //   setSearchGroups([]);
  //   setGroupVersion(groupVersion + 1);
  // };

  // const clearTag = () => {
  //   setSearchTags([]);
  //   setTagVersion(tagVersion + 1);
  // };

  const clearSearchBrand = () => {
    setSearchBrand("");
    setSearchBrandVersion(searchBrandVersion + 1);
  };

  const clearSearchTitle = () => {
    setSearchTitle("");
    setSearchTitleVersion(searchTitleVersion + 1);
  };

  // eslint-disable-next-line
  const deselectRows = (rows: StringKAnyVPair[]) => {
    const ids = rows.map(e => e.ProductId);
    let exist = false;

    for (let i = selectedRows.length - 1; i > -1; i--) {
      if (ids.indexOf(selectedRows[i].ProductId) > -1) {
        exist = true;
        selectedRows.splice(i, 1);
      }
    }

    if (exist) setSelectedRows([...selectedRows]);

    return selectedRows;
  };

  const hideSearchFilterPanel = () => {
    setFilterPanelVisible(false);
  };

  const onDragSelectedProductDrawerEnd = (evt: any) => {
    let dw = window.innerWidth - evt.pageX;
    const max = window.innerWidth / 2;
    const min = SELECTED_PRODUCT_DRAWER_DEFAULT_WIDTH;

    dw = dw > max ? max : dw;
    dw = dw < min ? min : dw;
    //console.log('end', evt.pageX, evt.clientX);
    setSelectedProductDrawerWidth(dw);
  };

  const onDragSelectedProductDrawerStart = (evt: any) => {
    //console.log('evt', evt);
    /*const img = new window.Image();

    img.src = 'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==';
    evt.dataTransfer.setDragImage(img, e, 0);*/
    evt.dataTransfer.setData('text/plain', 'resizeDrawer');
  };

  const onExtraFilterFieldChange = (
    field: string,
    val: number | string | string[],
  ) => {
    switch(field) {
      case 'agegroup':
        setExtraAgeGroup(val as string[]);
        break;

      case 'alternate':
        setExtraAlternateCode(val as string[]);
        break;

      case 'bundletype':
        setExtraBundleType(val as number);
        break;

      case 'categorycode':
        setExtraCategoryCode(val as string[]);
        break;

      case 'classcode':
        setExtraClassCode(val as string[]);
        break;

      case 'countryoforigin':
        setExtraCountryOfOrigin(val as string[]);
        break;

      case 'departmentcode':
        setExtraDepartmentCode(val as string[]);
        break;

      case 'divisioncode':
        setExtraDivisionCode(val as string[]);
        break;

      case 'gender':
        setExtraGender(val as string[]);
        break;

      case 'groupcode':
        setExtraGroupCode(val as string[]);
        break;

      case 'manufacturer':
        setExtraManufacturer(val as string[]);
        break;

      case 'model':
        setExtraModel(val as string[]);
        break;

      case 'productyear':
        setExtraProductYear(val as string);
        break;

      case 'remark':
        setExtraRemark(val as string);
        break;

      case 'subclasscode':
        setExtraSubClassCode(val as string[]);
        break;

      case 'subgroupcode':
        setExtraSubGroupCode(val as string[]);
        break;

      default:
        break;
    }
  };

  const onMouseEnterFilter = () => {
    if (filterPanelVisible) {
      //console.log('enter', filterPanelVisible);
      if (filterHiddenTimer.current) {
        clearTimeout(filterHiddenTimer.current);
        filterHiddenTimer.current = null;
      }
    }
  };

  const onMouseLeaveFilter = () => {
    if (filterPanelVisible) {
      //console.log('leave', filterPanelVisible);
      filterHiddenTimer.current = setTimeout(hideSearchFilterPanel, 1000);
    }
  };

  const showSearchFilterPanel = () => {
    filterState.current = {
      visible: true,
      timeStamp: new Date().getTime(),
    };
    setFilterPanelVisible(true);
  };

  const getCodeInputWrapperDom = () => {
    if (codeInputRef.current) {
      return codeInputRef.current.input.parentNode.parentNode;
    }
  };

  const onSearchbarFocus = () => {
    let ctn = getCodeInputWrapperDom();

    if (ctn) {
      ctn = ctn.parentNode.parentNode.parentNode.parentNode.parentNode;
      //console.log('ctn - - ->', ctn);
      const paddingLeft = 14;
      const rect = ctn.getBoundingClientRect();
      //console.log('focus ->', rect, { ...rect, y: rect.y + rect.height });
      setFilterPanelOutline({
        x: rect.x - paddingLeft,
        y: rect.y + rect.height,
        width: rect.width + paddingLeft * 2,
      });

      if (!filterPanelVisible) {
        showSearchFilterPanel();
      }
    }
  };

  const fetchChannelControlFlags = async () => {
    try {
      const res = await Products.getChannelControlFlags();

      //console.log('s', res);
      if (Array.isArray(res)) setCcfList(res);
    } catch (e) {
      notification.error({
        message: `Fetch Channel Control Flags error: ${e}`,
        duration: DEFAULT_ERR_MSG_DISPLAY_DURATION,
      });
    }
  };

  const showSearchConditionTags = (showFilter: boolean) => {
    const eccfLabels: string[] = ccfList
      .filter((c) => searchECCFs.indexOf(c.CHNLCtrlFlagNum) > -1)
      .map((c) => c.CHNLCtrlFlag);
    const ccfLabels: string[] = ccfList
      .filter((c) => searchCCFs.indexOf(c.CHNLCtrlFlagNum) > -1)
      .map((c) => c.CHNLCtrlFlag);
    const labels: string[] = labelList
      .filter((l) => searchLabels.indexOf(l.ProductLabelNum) > -1)
      .map((l) => l.ProductLabelName);
    const statusLabels = getOperationStatusOptions()
      .filter((e) => searchStatus.indexOf(e.code) > -1)
      .map((e) => e.description);

    return showFilter ? (
      <Space>
        <TableFilter columns={stateColumns} dataSource={filteredData} setFilteredData={setFilteredTempData} />
        {searchTitle.trim() && (
          <Tag closable onClose={clearSearchTitle}>
            <span className="field-label">Title</span>
            {searchTitle}
          </Tag>
        )}
        {statusLabels.length > 0 && (
          <Tag closable onClose={() => setSearchStatus([])}>
            <span className="field-label">Exclude Status</span>
            {statusLabels.join(", ")}
          </Tag>
        )}
        {ccfLabels.length > 0 && (
          <Tag closable onClose={clearChannelFlags}>
            <span className="field-label">Sales Channel</span>
            {ccfLabels.join(", ")}
          </Tag>
        )}
        {eccfLabels.length > 0 && renderFilterTag(
          'Exclude Sales Channel',
          eccfLabels,
          clearExcludeChannelFlags,
        )}
        {searchBrand.trim() && (
          <Tag closable onClose={clearSearchBrand}>
            <span className="field-label">Brand</span>
            {searchBrand}
          </Tag>
        )}
        {labels.length > 0 && (
          <Tag closable onClose={clearLabels}>
            <span className="field-label">Tags</span>
            {labels.join(", ")}
          </Tag>
        )}
        {searchGroupsStr.length > 0 && (
          <Tag
            closable
            onClose={() => {
              setSearchGroups([]);
              setSearchGroupsStr([]);
              setSearchGroupsVersion(searchGroupsVersion + 1);
            }}
          >
            <span className="field-label">Group</span>
            {searchGroupsStr.join(", ")}
          </Tag>
        )}
        {searchTags.length > 0 && (
          <Tag
            closable
            onClose={() => {
              setSearchTags([]);
              setSearchTagVersion(searchTagVersion + 1);
              // setSearchTagsStr('')
            }}
          >
            <span className="field-label">Tag</span>
            {searchTags.join(", ")}
          </Tag>
        )}
        {typeof extraBundleType === 'number' && renderFilterTag(
          'Bundle Type',
          getBundleTypeLabel(extraBundleType),
          () => setExtraBundleType(undefined),
        )}
        {extraCategoryCode.length > 0 && renderFilterTag(
          'Category',
          extraCategoryCode,
          () => setExtraCategoryCode([]),
        )}
        {extraSubClassCode.length > 0 && renderFilterTag(
          'Subcategory',
          extraSubClassCode,
          () => setExtraSubClassCode([]),
        )}
        {extraClassCode.length > 0 && renderFilterTag(
          'Class',
          extraClassCode,
          () => setExtraClassCode([]),
        )}
        {extraGroupCode.length > 0 && renderFilterTag(
          'Group',
          extraGroupCode,
          () => setExtraGroupCode([]),
        )}
        {extraSubGroupCode.length > 0 && renderFilterTag(
          'Subgroup',
          extraSubGroupCode,
          () => setExtraSubGroupCode([]),
        )}
        {extraDivisionCode.length > 0 && renderFilterTag(
          'Division',
          extraDivisionCode,
          () => setExtraDivisionCode([]),
        )}
        {extraDepartmentCode.length > 0 && renderFilterTag(
          'Department',
          extraDepartmentCode,
          () => setExtraDepartmentCode([]),
        )}
        {extraManufacturer.length > 0 && renderFilterTag(
          'Manufacturer',
          extraManufacturer,
          () => setExtraManufacturer([]),
        )}
        {extraModel.length > 0 && renderFilterTag(
          'Model',
          extraModel,
          () => setExtraModel([]),
        )}
        {extraAlternateCode.length > 0 && renderFilterTag(
          'Alternate',
          extraAlternateCode,
          () => setExtraAlternateCode([]),
        )}
        {extraRemark.trim() && renderFilterTag(
          'Remark',
          extraRemark,
          () => setExtraRemark(''),
        )}
        {extraProductYear.trim() && renderFilterTag(
          'Product Year',
          extraProductYear,
          () => setExtraProductYear(''),
        )}
        {extraGender.length > 0 && renderFilterTag(
          'Gender',
          extraGender,
          () => setExtraGender([]),
        )}
        {extraAgeGroup.length > 0 && renderFilterTag(
          'Age Group',
          extraAgeGroup,
          () => setExtraAgeGroup([]),
        )}
        {searchSorter.trim() && (
          <Tag>
            <span className="field-label">Sort By</span>
            {searchSorter}
          </Tag>
        )}
      </Space>
    ) : (
      <Space></Space>
    );
  };

  const getAttrOptions = (attr: any) => {
    let ret: StringKAnyVPair[] = [];

    if (attr && typeof attr === "object") {
      if (typeof attr.optionList === "string" && attr.optionList.trim()) {
        attr.optionList
          .split("|")
          .map((e: string) => e.trim())
          //.filter((e: string) => e)
          .map((e: string) => ({ value: e }))
          .forEach((e: StringKAnyVPair) => ret.push(e));
      }
    }

    return ret;
  };

  const getProductListStyle = useCallback(async () => {
    const value = await getProfileSettingValue("ProductListStyle", "0");
    setProductListStyle(value);
  }, []);

  const getGroupList = async () => {
    try {
      const {
        data = [],
        isSuccess,
        message: resMsg = "",
      } = await fetchContentSourceType();
      if (isSuccess) {
        setGroupList(data);
      } else {
        message.error(resMsg || "No group list found");
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    getGroupList();
  }, []);

  const closeImageEditor = () => {
    setEditImageDialogVisible(false);
  };

  const closePrintRowsDialog = () => {
    setPrintRowsDialogVisible(false);
  };

  const closeSelectedProductDrawer = () => {
    setSelectedProductDrawerVisible(false);
  };

  const closeSimpleSelectorEditor = () => {
    setEditSelectorDialogVisible(false);
  };

  const searchCodeTypeSelector = () => (
    <Select
      defaultValue={searchCodeType}
      onChange={onSelectSearchCodeType}
      onMouseDown={onSearchbarFocus}
      onMouseEnter={onMouseEnterFilter}
      onMouseLeave={onMouseLeaveFilter}
      style={{ width: 230 }}
    >
      <Option value={CODE_OPTION_STYLE_EQUALS}>Style Equals</Option>
      <Option value={CODE_OPTION_STYLE_CONTAINS}>Style Contains</Option>
      <Option value={CODE_OPTION_STYLE_LIST}>Style List</Option>
      <Option value={CODE_OPTION_SKU_LIST}>SKU List</Option>
      <Option value={CODE_OPTION_SKU_EQUALS}>SKU Equals</Option>
      <Option value={CODE_OPTION_SKU_CONTAINS}>SKU Contains</Option>
      <Option value={CODE_OPTION_COLOR_EQUALS}>Substyle Equals</Option>
      <Option value={CODE_OPTION_COLOR_CONTAINS}>Substyle Contains</Option>
      <Option value={CODE_OPTION_COLOR_LIST}>Substyle List</Option>
      <Option value={CODE_OPTION_UPC_EQUALS}>UPC Equals</Option>
      <Option value={CODE_OPTION_UPC_LIST}>UPC List</Option>
    </Select>
  );

  const filterValue = [
    { name: "SKU", operator: "contains", type: GRID_FILTER13, value: "" },
    {
      name: "ProductTitle",
      operator: "contains",
      type: GRID_FILTER11,
      value: "",
    },
    { name: "UPC", operator: "eq", type: GRID_FILTER12, value: "" },
    { name: "QtyTotal", operator: "gte", type: GRID_FILTER21, value: null },
  ];

  const gridFilterTypes = Object.assign(
    {
      styleCodeFilter: {
        type: "string",
        emptyValue: "",
        operators: [
          { name: "SKU Contains", fn: () => true },
          { name: "SKU Equals", fn: () => true },
        ],
      },
    },
    filterTypes
  );

  const dataSource = async (): Promise<{ data: any[]; count: number }> => {
    return {
      data: filteredTempData,
      count: totalCount,
    };
  };
  const closeProductDetailDialog = () => {
    setDetailVisible(false);
    setCurrentProduct({});
  };

  const excludeSalesChannel = () => {
    return (
      <div className="filter-form-cell">
        <FormLabel>Exclude Sales Channel</FormLabel>
        <ChannelControlFlagSelector
          channelFlagVersion={excludeChannelFlagVersion}
          onChange={onSelectExcludeCCFs}
        />
      </div>
    );
  };

  const fetchProductAts = async (products: StringKAnyVPair[]) => {
    try {
      //console.log('pd', products);
      const ids = products.map((e) => {
        //pDict[e.CentralProductNum] = e;
        //return e.ProductId;
        return e.CentralProductNum;
      });
      //console.log('ids', ids);
      const res = await fetchProductInventoryAts(ids);

      if (Array.isArray(res)) {
        const pDict: StringKAnyVPair = {};
        //console.log('res', res);
        res.forEach(e => {
          pDict[e.centralProductNum] = e.atsQuantity;
        });
        //console.log('pd', pDict);
        products.forEach(e => {
          e.atsQuantity = pDict[e.CentralProductNum];
        });
      }
    } catch(e) {
    } finally {
    }
  };

  // eslint-disable-next-line
  const fetchProductList = async (options: StringKAnyVPair = {}) => {
    const { skip, top } = options;

    //gridRef?.current.deselectAll();
    setSelected({});
    setIsFetching(true);

    try {
      // console.log("options ->", getSearchOptions());
      const params = getSearchOptions();
      const { ProductList, ProductTotalCount } = await fetchSimpleProductList2(
        typeof skip === "number" && skip >= 0 ? skip : pageSkip,
        typeof top === "number" && top >= 0 ? top : pageTop,
        params
      );
      if (!dataInited) {
        setDataInited(true);
      }
      if (Array.isArray(ProductList)) {
        if (searchGroup === GROUP_OPTION_STYLE || searchGroup === GROUP_OPTION_COLOR) {
          ProductList.forEach((e) => {
            if (["Product", "Bundle"].indexOf(e.Type) < 0) {
              e.nodes = null;
            }
          });
        }

        setFilteredData(ProductList);
        setTotalCount(ProductTotalCount || ProductList.length);
        setSearchBegun(true);
        // do not use the row detail feature to show product children
        // setSearchGroupMode(isSearchGroupMode(searchCodeType));
        setSearchGroupMode(false);
        // setSearchTreeMode(isSearchGroupMode(searchCodeType));
        setSearchTreeMode(searchGroup === GROUP_OPTION_STYLE || searchGroup === GROUP_OPTION_COLOR)

        if (ProductList.length > 0) {
          setTimeout(async () => {
            await fetchProductAts(ProductList);
            await fetchProductParticalData(ProductList);
            setFilteredData([...ProductList]);
            setDataGridSelectionByData(ProductList);
          }, 0);
          await fetchProductExtData(ProductList);
        } else {
          setIsFetching(false);
        }
      } else {
        setIsFetching(false);
      }
    } catch (e) {
      setIsFetching(false);
      setFilteredData([]);
      setTotalCount(0);
      message.error(`Fetch products error: ${e}`);
      console.log("Fetch products error:", e);
    } finally {
      setIsFetching(false);
    }
  };

  const fetchProductExtData = async (products: StringKAnyVPair[]) => {
    try {
      if (cancel !== undefined) {
        cancel();
      }
      const pDict: StringKAnyVPair = {};
      const ids = products.map((e) => {
        pDict[e.ProductId] = e;

        return e.ProductId;
      });

      const params = {
        products: ids,
        //groups: searchGroups,
        groups: [],
        views: [],
        tags: searchTags,
        //assigns: origin.map(i => i.attributeId),
        assigns: [],
      };

      // setExtLoading(true);
      const data = await fetchProductCopywrtingLinesheet(
        // ids,
        params,
        new CancelToken(function executor(c) {
          cancel = c;
        })
      );
      if (data.length > 0) {
        setStateColumns([
          ...columns,
          ...data[0].mappedPreviewers.map((i: any, index: number) => {
            return {
              name: `ext${index}`,
              header: originKeyValue[i.attributeNum] ? originKeyValue[i.attributeNum] : i.previewDisplayName,
              defaultFlex: 1,
              dataAttr: i,
              minWidth: 150,
              render: (value: any) => {
                const { data, rowIndex } = value;
                return (
                  <SetValueCell
                    value={data[`ext${index}`]}
                    valueType={checkStringType(data[`ext${index}`])}
                    elementDataType={i.elementDataType}
                    editable={!(i.source === SOURCE_TYPE_ERP || i.elementDataType === ATTRIBUTE_DATA_TYPE_VIRTUAL)}
                    onButtonClick={() => {
                      if (i.source === SOURCE_TYPE_ERP) return;

                      const attr = getDataAttrById(i.attributeNum);
                      const opts = getAttrOptions(attr);

                      //console.log('dpid', data.ProductId);
                      setCurrentValue({
                        data: data[`ext${index}`],
                        options: opts,
                        previewValue: data[`ext${index}`],
                        previewDisplayName: i.previewDisplayName,
                        previewID: i.previewID,
                        productId: data.ProductId,
                        //productId: getRealProductId(data.ProductId),
                        name: `ext${index}`,
                        attributeNum: i.attributeNum,
                        rowIndex: rowIndex,
                        editable: true,
                        //editable: !(i.source === SOURCE_TYPE_ERP),
                        sku: data.SKU,
                        productType: data.ProductType,  
                      });

                      if (attr) {
                        if (opts.length > 0) {
                          setEditSelectorDialogVisible(true);
                        } else {
                          switch (attr.elementDataType) {
                            case ATTRIBUTE_DATA_TYPE_IMAGEURL:
                              setEditImageDialogVisible(true);
                              break;

                            default:
                              setValueModalVisible(true);
                          }
                        }
                      } else {
                        setValueModalVisible(true);
                      }
                    }}
                  />
                );
              },
            };
          }),
        ]);
        data.map((item: any) => {
          if (pDict[item.productId]) {
            item.mappedPreviewers.map((i: any, index: number) => {
              pDict[item.productId][`ext${index}`] = i.previewValue;
              return true;
            });
          }
          return true;
        });
        // setFilteredData(Object.values(pDict));
      }
      // setExtLoading(false);
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log("request canceled..");
      }
    }
  };

  const fetchProductParticalData = async (products: StringKAnyVPair[]) => {
    // setIsFetching(true);

    try {
      const pDict: StringKAnyVPair = {};
      const ids = products.map((e) => {
        pDict[e.ProductId] = e;

        return e.ProductId;
      });
      const data = await fetchSimpleProductPartialData(ids);

      if (data && Array.isArray(data)) {
        // console.log('d->', data, products);
        data.forEach((e) => {
          if (pDict[e.productId]) {
            const obj = { ...e };

            delete obj.productId;
            // pDict[e.productId] = obj;
            // console.log('e->', obj);
            for (let k in obj) {
              pDict[e.productId][k] = obj[k];
            }
          }
        });
        //console.log('p-->', products);
        //setFilteredData([...products] as Entities.ProductProfile[]);
      }
    } catch (e) {
      message.error(`Fetch partial error: ${e}`);
      console.error("Fetch partial error:", e);
    } finally {
      // setIsFetching(false);
    }
  };

  const getCollectionFilters = () => {
    const ret: StringKAnyVPair[] = [];

    switch (searchCodeType) {
      case CODE_OPTION_SKU_LIST:
      case CODE_OPTION_COLOR_LIST:
      case CODE_OPTION_STYLE_LIST:
      case CODE_OPTION_UPC_LIST:
        const codes = searchMultiCode
          .split("\n")
          .map((e) => e.trim())
          .filter((e) => e);

        ret.push({
          filterName: getFilterNameBySearchCodeType(), collectionFilterValues: codes,
          op: 1,
        });
        break;
    }

    ret.push({
      filterName: "ProductStatus",
      collectionFilterValues: searchStatus,
      op: 7,
    });

    if (searchCCFs.length > 0) {
      ret.push({
        filterName: "ChannelControlFlag",
        //filterValue: searchCCFs,
        collectionFilterValues: searchCCFs,
        op: OPERATION_EQUALS,
      });
    }

    if (searchECCFs.length > 0) {
      ret.push({
        filterName: "ChannelControlFlag",
        //filterValue: searchCCFs,
        collectionFilterValues: searchECCFs,
        op: 7,
      });
    }

    if (searchLabels.length > 0) {
      ret.push({
        filterName: "Labels",
        //filterValue: searchLabels,
        collectionFilterValues: searchLabels,
        op: OPERATION_EQUALS,
      });
    }

    if (searchBrand) {
      ret.push({
        filterName: "Brand",
        //filterValue: searchBrand.split(','),
        collectionFilterValues: searchBrand.split(','),
        op: OPERATION_CONTAIN,
      });
    }

    if ((searchTags || []).length > 0) {
      ret.push({
        filterName: "Tag",
        collectionFilterValues: searchTags,
        op: OPERATION_EQUALS,
      });
    }

    if (extraAgeGroup.length > 0) {
      ret.push({
        filterName: 'AgeGroup',
        collectionFilterValues: extraAgeGroup,
        op: OPERATION_CONTAIN,
      });
    }

    if (extraAlternateCode.length > 0) {
      ret.push({
        filterName: 'AlternateCode',
        collectionFilterValues: extraAlternateCode,
        op: OPERATION_CONTAIN,
      });
    }

    if (extraCategoryCode.length > 0) {
      ret.push({
        filterName: 'CategoryCode',
        collectionFilterValues: extraCategoryCode,
        op: OPERATION_CONTAIN,
      });
    }

    if (extraClassCode.length > 0) {
      ret.push({
        filterName: 'ClassCode',
        collectionFilterValues: extraClassCode,
        op: OPERATION_CONTAIN,
      });
    }

    if (extraCountryOfOrigin.length > 0) {
      ret.push({
        filterName: 'CountryOfOrigin',
        collectionFilterValues: extraCountryOfOrigin,
        op: OPERATION_CONTAIN,
      });
    }

    if (extraDepartmentCode.length > 0) {
      ret.push({
        filterName: 'DepartmentCode',
        collectionFilterValues: extraDepartmentCode,
        op: OPERATION_CONTAIN,
      });
    }

    if (extraDivisionCode.length > 0) {
      ret.push({
        filterName: 'DivisionCode',
        collectionFilterValues: extraDivisionCode,
        op: OPERATION_CONTAIN,
      });
    }

    if (extraGender.length > 0) {
      ret.push({
        filterName: 'Gender',
        collectionFilterValues: extraGender,
        op: OPERATION_CONTAIN,
      });
    }

    if (extraGroupCode.length > 0) {
      ret.push({
        filterName: 'GroupCode',
        collectionFilterValues: extraGroupCode,
        op: OPERATION_CONTAIN,
      });
    }

    if (extraManufacturer.length > 0) {
      ret.push({
        filterName: 'Manufacturer',
        collectionFilterValues: extraManufacturer,
        op: OPERATION_CONTAIN,
      });
    }

    if (extraModel.length > 0) {
      ret.push({
        filterName: 'Model',
        collectionFilterValues: extraModel,
        op: OPERATION_CONTAIN,
      });
    }

    if (extraProductYear) {
      ret.push({
        filterName: 'ProductYear',
        collectionFilterValues: [extraProductYear],
        op: OPERATION_CONTAIN,
      });
    }

    /*if (extraRemark) {
      ret.push({
        filterName: 'Remark',
        collectionFilterValues: [extraRemark],
        op: OPERATION_CONTAIN,
      });
    }*/

    if (extraSubClassCode.length > 0) {
      ret.push({
        filterName: 'SubClassCode',
        collectionFilterValues: extraSubClassCode,
        op: OPERATION_CONTAIN,
      });
    }

    if (extraSubGroupCode.length > 0) {
      ret.push({
        filterName: 'SubGroupCode',
        collectionFilterValues: extraSubGroupCode,
        op: OPERATION_CONTAIN,
      });
    }

    return ret;
  };

  const getFilterNameBySearchCodeType = () => {
    switch (searchCodeType) {
      case CODE_OPTION_COLOR_CONTAINS:
      case CODE_OPTION_COLOR_EQUALS:
      case CODE_OPTION_COLOR_LIST:
        return "ColorPatternCode";

      case CODE_OPTION_SKU_CONTAINS:
      case CODE_OPTION_SKU_EQUALS:
      case CODE_OPTION_SKU_LIST:
        return "SKU";

      case CODE_OPTION_STYLE_CONTAINS:
      case CODE_OPTION_STYLE_EQUALS:
      case CODE_OPTION_STYLE_LIST:
        return "StyleCode";

      case CODE_OPTION_UPC_EQUALS:
      case CODE_OPTION_UPC_LIST:
        return "UPC";
    }
  };

  const getOperateCodeBySearchCodeType = () => {
    switch (searchCodeType) {
      case CODE_OPTION_COLOR_CONTAINS:
      case CODE_OPTION_SKU_CONTAINS:
      case CODE_OPTION_STYLE_CONTAINS:
        return OPERATION_CONTAIN;

      case CODE_OPTION_COLOR_EQUALS:
      case CODE_OPTION_SKU_EQUALS:
      case CODE_OPTION_STYLE_EQUALS:
      case CODE_OPTION_UPC_EQUALS:
        return OPERATION_EQUALS;

      case CODE_OPTION_SKU_LIST:
      case CODE_OPTION_COLOR_LIST:
      case CODE_OPTION_STYLE_LIST:
      case CODE_OPTION_UPC_LIST:
        return OPERATION_LIST;
    }
  };

  const getSearchGrouper = (/*filters: any[]*/) => {
    let ret: any = null;

    // if (isCommonGroupOption(searchCodeType) || isSKUCode(searchCodeType)) {
    let groupName = "";

    switch (searchGroup) {
      case GROUP_OPTION_COLOR:
        groupName = "ColorPatternCode";
        break;

      case GROUP_OPTION_PRODUCT:
        groupName = "Product";
        break;

      case GROUP_OPTION_STYLE:
        groupName = "StyleCode";
        break;
    }

    if (groupName) {
      ret = { groupName };
    }
    // }

    return ret;
  };

  const getSearchOptions = () => {
    const filters: StringKAnyVPair[] = [];

    //if (searchCode) {
    if (
      searchCodeType !== CODE_OPTION_SKU_LIST &&
      searchCodeType !== CODE_OPTION_COLOR_LIST &&
      searchCodeType !== CODE_OPTION_STYLE_LIST &&
      searchCodeType !== CODE_OPTION_UPC_LIST
    ) {
      filters.push({
        filterName: getFilterNameBySearchCodeType(),
        filterValue: searchCode,
        op: getOperateCodeBySearchCodeType(),
      });
    }
    //}

    if (searchTitle) {
      filters.push({
        filterName: "ProductTitle",
        filterValue: searchTitle,
        op: OPERATION_CONTAIN,
      });
    }

    /*if (searchCCFs.length > 0) {
      filters.push({
        filterName: "ChannelControlFlag",
        filterValue: searchCCFs.join("|"),
        op: OPERATION_EQUALS,
      });
    }*/

    if (searchCFs.length > 0) {
      filters.push({
        filterName: "Classification",
        filterValue: searchCFs.join("|"),
        op: OPERATION_EQUALS,
      });
    }

    /*if (searchLabels.length > 0) {
      filters.push({
        filterName: "Labels",
        filterValue: searchLabels.join("|"),
        op: OPERATION_EQUALS,
      });
    }*/

    /*if (searchBrand) {
      filters.push({
        filterName: "Brand",
        filterValue: searchBrand,
        op: OPERATION_CONTAIN,
      });
    }*/

    /*if ((searchTags || []).length > 0) {
      filters.push({
        filterName: "Tag",
        filterValue: searchTags.join("|"),
        op: OPERATION_EQUALS,
      });
    }*/

    if ((searchGroups || []).length > 0) {
      filters.push({
        filterName: "ContentSourceGroup",
        filterValue: searchGroups.join("|"),
        op: OPERATION_EQUALS,
      });
    }

    if (typeof extraBundleType === 'number') {
      filters.push({
        filterName: 'BundleType',
        filterValue: extraBundleType,
        op: OPERATION_EQUALS,
      });
    }

    if (extraRemark) {
      filters.push({
        filterName: 'Remark',
        filterValue: extraRemark,
        op: OPERATION_CONTAIN,
        //op: OPERATION_EQUALS,
      });
    }

    return {
      queryFilters: filters,
      queryGrouper: getSearchGrouper(),
      querySorters: getSearchSorter(),
      queryCollectionFilters: getCollectionFilters(),
    };
  };

  const getSearchSorter = () => {
    let ret: any[] = [];

    if (searchSorter) {
      const fields = searchSorter.split(" ");

      if (fields.length === 2) {
        ret.push({
          sortByName: fields[0],
          sortOps: fields[1],
        });
      }
    }

    return ret;
  };

  const saveBasic = async (newValue: string, data: any, overwrite = false) => {
    if (!data || !data.productId || !data.previewID || !data.attributeNum) {
      message.warning(`Data error`);
      return;
    }
    if (newValue === data.previewValue) {
      setCurrentValue(undefined);
      setEditImageDialogVisible(false);
      setValueModalVisible(false);
      return;
    }
    try {
      const productBasic: StringKAnyVPair = {};
      productBasic[data.previewID] = newValue;
      setIsLoading(true);
      await saveCopywritingSingleElement(
        getRealProductId(data.productId),
        data.sku,
        data.attributeNum,
        {
          CopywritingElementNum: data.attributeNum,
          CopywritingValue: newValue,
          overwrite,
        }
      );
      // this code must be put before setCurrentValue
      setEditImageDialogVisible(false);
      setCurrentValue(undefined);
      setValueModalVisible(false);
      message.success(`Saved ${data.previewDisplayName} successfully`);
      const temp = [...filteredData];

      if (temp[data.rowIndex]) {
        //console.log('d ->', data);
        if (temp[data.rowIndex].ProductId === data.productId) {
          //console.log('bs', temp[data.rowIndex][data.name], data);
          temp[data.rowIndex][data.name] = newValue;
          //console.log('s ->', temp[data.rowIndex]);
          if (Array.isArray(temp[data.rowIndex].nodes) && overwrite) {
            //console.log('ready sd children', newValue, data, temp[data.rowIndex].nodes);
            setDataListValue(temp[data.rowIndex].nodes,  data, newValue);
          }
        } else {
          setDataValueByProductId(temp, data.productId, data.name, newValue);
        }

        setFilteredData(temp);
      }
    } catch (error) {
      notification.error({
        message: `Save data error: ${error}`,
        duration: DEFAULT_ERR_MSG_DISPLAY_DURATION,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const setDataGridSelectionByData = (
    prdList: StringKAnyVPair[],
  ) => {
    const sd: StringKAnyVPair = {};
    const ids = selectedRows.map(e => e.ProductId);

    prdList.forEach(e => {
      if (ids.indexOf(e.ProductId) > -1) {
        sd[e.ProductId] = e;
      }
    });
    setSelected(sd);
  };

  const setDataListValue = (
    dl: StringKAnyVPair[],
    data: StringKAnyVPair,
    value: any,
  ) => {
    dl.forEach(d => {
      //console.log(d, data);
      d[data.name] = value;
    });
  };

  const setDataValueByProductId = (
    dl: StringKAnyVPair[],
    prdId: string,
    key: string,
    value: string,
  ) => {
    //console.log('prd id', prdId, key, dl);
    dl.forEach(d => {
      if (d.ProductId) {
        if (getRealProductId(prdId) === d.ProductId) {
          d[key] = value;
          //console.log('sd', d);
        } else if (prdId.indexOf(d.ProductId) > -1) {
          if (Array.isArray(d.nodes)) {
            setDataValueByProductId(d.nodes, prdId, key, value);
          }
        }
      }
    });
  };

  const handleSearchProducts = () => {
    setInited(true);
    fetchProductList();
    fetchChannelControlFlags();
    fetchLabels();
  };

  // const isColorCode = (code: number) => {
  //   return (
  //     [
  //       CODE_OPTION_COLOR_CONTAINS,
  //       CODE_OPTION_COLOR_EQUALS,
  //       CODE_OPTION_COLOR_LIST,
  //     ].indexOf(code) > -1
  //   );
  // };

  // const isCommonGroupOption = (code: number) => {
  //   return isColorCode(code) || isStyleCode(code);
  // };

  // const isSearchGroupMode = (code: number) => {
  //   return isCommonGroupOption(code) && searchGroup !== GROUP_OPTION_NO_GROUP;
  // };

  // const isSKUCode = (code: number) => {
  //   return (
  //     [
  //       CODE_OPTION_SKU_CONTAINS,
  //       CODE_OPTION_SKU_EQUALS,
  //       CODE_OPTION_SKU_LIST,
  //     ].indexOf(code) > -1
  //   );
  // };

  // const isStyleCode = (code: number) => {
  //   return (
  //     [
  //       CODE_OPTION_STYLE_CONTAINS,
  //       CODE_OPTION_STYLE_EQUALS,
  //       CODE_OPTION_STYLE_LIST,
  //     ].indexOf(code) > -1
  //   );
  // };

  const openPrintRowsDialog = () => {
    setPrintRowsDialogVisible(true);
  };

  const openProductDetailDialog = (
    product: StringKAnyVPair,
    productId: string = '',
  ) => {
    setDetailVisible(true);
    // console.log("pid->", productId);
    setCurrentProduct(product);
  };

  const openSelectedProductDrawer = () => {
    setSelectedProductDrawerVisible(true);
  };

  const gridColumns = (isGroupMode = false) => {
    const colDef = [...stateColumns];
    const tempColumns: any[] = [];

    for (let i = 0; i < colDef.length; i++) {
      if (productListStyle === "2") {
        if (["Style", "Substyle"].includes(colDef[i].header)) {
          continue;
        }
      }
      if (colDef[i].header === "Image") {
        colDef[i].render = (p: any) => {
          const { value, data } = p;
          //const src = value || "https://via.placeholder.com/300";
          const src = value || ImagePlaceholder;
          const isTreeNode = isGroupMode;
          // const isTreeNode = true;
          // console.log('->is->', isTreeNode, searchCodeType);

          return (
            <ImageContainer
              key={data.ProductId}
              className={isTreeNode ? "image-tree-ctn" : ""}
            >
              <Image fallback={ImagePlaceholder} width={28} height={28} src={src} />
            </ImageContainer>
          );
        };
        tempColumns.push(colDef[i]);
        continue;
      } else if (colDef[i].header === "SKU") {
        colDef[i].render = (p: any) => {
          const { data } = p;
          const pids = data.ProductId.split("/");
          const productId = pids[pids.length - 1] || "-";

          return (
            <ERPLinkTableCell type={3} value={data.SKU} onClick={()=> openProductDetailDialog(data, `${productId}`)} />
          );
        };
        tempColumns.push(colDef[i]);
        continue;
      }
      tempColumns.push(colDef[i]);
    }

    return tempColumns;
  };

  const fetchChildrenPartialData = async (
    node: StringKAnyVPair,
    ProductList: Entities.ProductProfile[]
  ) => {
    await fetchProductParticalData(ProductList);
    await fetchProductExtData(ProductList);
    node.nodes = ProductList;
    //console.log('fd', filteredData);
    updateGridRow(node as Entities.ProductProfile);
  };

  const getDataAttrById = (id: number) => {
    const fs = eleAttrs.filter((e) => e.copywritingElementNum === id);
    if (fs.length > 0) {
      return fs[0];
    }
  };

  const loadAlternateList = async () => {
    const res = await fetchAlternateList();

    //console.log('res ->', res);
    if (Array.isArray(res)) {
      setExtraAlternateOptions(res.map(e => ({
        value: e.code,
        label: e.code,
      })));
    }
  };

  const loadControlTowerOrigin = useCallback(async () => {
    try {
      const res = await getControlTowerOrigin()
      if(res){
        const temp: StringKAnyVPair = {}
        res.map((i:any) => {
          temp[i.attributeId] = i.searchHeaderName;
          return true
        })
        setOriginKeyValue(temp)
      }
    } catch (error) {
      
    }
  },[])

  const loadCopywritingElements = async () => {
    try {
      const { data } = await fetchCopywritingelements();

      if (data && Array.isArray(data)) {
        setEleAttrs(data);
      }
    } catch (e) {
      notification.error({
        message: `Load attribute data error: ${e}`,
        duration: DEFAULT_ERR_MSG_DISPLAY_DURATION,
      });
    }
  };

  // eslint-disable-next-line
  const loadInitialData = async () => {
    const sv = await loadStyleVariations();

    if (sv && typeof sv === 'object') {
      console.log('sv', sv);
      setStyleVariation(sv);
    }

    loadAlternateList();
  };

  const loadNextLevelProducts = async (data: any) => {
    // console.log('--->', data);
    const { node } = data;
    let ret: any = null;

    //setIsFetching(true);
    try {
      const { ProductList } = await fetchSimpleProductGroup(node.ProductId);

      if (Array.isArray(ProductList)) {
        //console.log('-->', ProductList);
        //setProducts(ProductList);
        // if (ProductList.length > 0) {
        //   await fetchProductParticalData(ProductList);
        //   await fetchProductExtData(ProductList);

        // }
        if (ProductList.length > 0) {
          await fetchProductAts(ProductList);
          await fetchChildrenPartialData(node, ProductList);
        }

        ret = ProductList;
      }
    } finally {
      // setIsFetching(false);
    }

    return ret;
  };

  const onFilterValueChange = (val: any) => {
    // console.log('vv->', val);
  };

  const onGroupModeChange = (val: any) => {
    setSearchGroup(val.target.value);
  };

  const onLimitChange = (limit: number) => {
    // console.log('limit -->', limit);
    fetchProductList({ top: limit });
    setPageTop(limit);
    //gridRef?.current.deselectAll();
  };

  const onPageChange = (page: number, pageSize: any) => {
    //console.log(page, pageSize, '<-');
    if (pageSize && pageSize !== pageTop) {
      setPageSkip(0);
      setPageTop(pageSize);
      fetchProductList({ skip: 0, top: pageSize });
    } else {
      const skip = (page - 1) * pageTop;

      setPageSkip(skip);
      fetchProductList({ skip });
    }
  };

  const onSelectChannelControlFlags = (values: any[]) => {
    //console.log('v-->', values);
    setSearchCCFs(values);
  };

  // eslint-disable-next-line
  const onSelectClassifications = (values: any[]) => {
    //console.log('v-->', values);
    setSearchCFs(values);
  };

  const onSelectLabels = (values: any[]) => {
    //console.log('v-->', values);
    setSearchLabels(values);
  };

  // const onSelectSortType = (value: any) => {
  //   // console.log('-->', value);
  //   setSearchSorter(value);
  // };

  const onSearchCodeChange = (evt: any) => {
    //console.log('-->', evt.target.value);
    //setSearchCode(evt.target.value as string);
    const value = evt.target.value as string;

    setTimeout(() => setSearchCode(value), 0);
  };

  const onSelectExcludeCCFs = (values: any[]) => {
    setSearchECCFs(values);
  };

  const onSelectGroups = (groups: number[], option: any) => {
    setSearchGroups(groups);
    setSearchGroupsStr(option ? option.map((i: any) => i.label) : []);
  };

  const onSelectTags = (value: string) => {
    let str: string = value
      .trim()
      .replace(/[\r\n]/g, ",")
      .replace(/,+/g, ",")
      .replace("/,$/gi", "");
    const tags = str.split(",");
    setSearchTags(tags.filter((item) => item));
  };

  const onSearchTitleChange = (evt: any) => {
    //console.log('-->', evt.target.value);
    //setSearchTitle(evt.target.value as string);
    const value = evt.target.value as string;

    setTimeout(() => setSearchTitle(value), 0);
  };

  const onSelectSearchCodeType = (value: any) => {
    switch (value) {
      /*case CODE_OPTION_COLOR_CONTAINS:
      case CODE_OPTION_COLOR_EQUALS:
      case CODE_OPTION_COLOR_LIST:
        setSearchGroup(GROUP_OPTION_COLOR);
        break;
      case CODE_OPTION_STYLE_CONTAINS:
      case CODE_OPTION_STYLE_EQUALS:
        //case CODE_OPTION_STYLE_EQUALS:
        setSearchGroup(GROUP_OPTION_STYLE);
        break;*/
      case CODE_OPTION_UPC_LIST:
      case CODE_OPTION_UPC_EQUALS:
        setSearchGroup(GROUP_OPTION_PRODUCT);
        break;
      case CODE_OPTION_SKU_CONTAINS:
      case CODE_OPTION_SKU_EQUALS:
      case CODE_OPTION_SKU_LIST:
        // case CODE_OPTION_UPC_LIST:
        // case CODE_OPTION_UPC_EQUALS:
        setSearchGroup(GROUP_OPTION_NO_GROUP);
        break;
    }

    setSearchCodeType(value);

    if (
      value === CODE_OPTION_SKU_LIST ||
      value === CODE_OPTION_COLOR_LIST ||
      value === CODE_OPTION_STYLE_LIST ||
      value === CODE_OPTION_UPC_LIST
    ) {
      setTimeout(() => {
        setSearchMultiCode("");
        codeListInputRef?.current?.focus();
        //codeListInputRef?.current?.select();
      }, 0);
    } else {
      setTimeout(() => {
        codeInputRef?.current?.select();
        codeInputRef?.current?.focus();
      }, 0);
    }
  };

  const onSelectionChange = useCallback((props) => {
    const { selected, data, unselected } = props;

    //console.log('sel', selected, data, unselected, props);
    setSelected(selected);

    if (gridRef && gridRef.current && data && data.ProductId) {
      const top1 = gridRef.current.scrollTop;

      setTimeout(() => {
        const top2 = gridRef.current.scrollTop;
        //console.log(top1, top2);
        if (top1 !== top2) {
          //gridRef?.current.scrollToId(data.ProductId, {direction: 'top', duration: 100, offset: top1});
          gridRef?.current.smoothScrollTo(top1, {orientation: 'vertical', duration: 100});
          // maybe need smoothScrollTo to scroll horizontally
        }
      }, 500);
    }

    if (selected === true && !unselected) {
      //setSelectedRows([ ...(data as any) ]);
      addSelectedRows([ ...(Array.isArray(data) ? data : [data]) ]);
      openSelectedProductDrawer();
    } else {
      if (!unselected) {
        const sd = Object.values(selected as any);
        let rows: StringKAnyVPair[] = [];
        console.log('s', selected, data, unselected);
        //setSelectedRows(Object.values(selected as any));
        if (sd.length > 0) {
          rows = addSelectedRows(sd as any);

          if (data && data.ProductId && !(data.ProductId in selected)) {
            rows = deselectRows([data]);
          }
        } else {
          //deselectRows(sd.length > 0 ? sd : filteredData);
          rows = deselectRows(filteredData);
        }

        if (rows.length > selectedRows.length) openSelectedProductDrawer();
      } else {
        setSelectedRows((prev) => {
          //console.log('prev', prev);
          const exist = prev.filter((item) => item.ProductId === (data as any).ProductId);
          const rawLength = prev.length;

          if (exist.length > 0) {
            return prev.filter((item) => item.ProductId !== (data as any).ProductId);
          } else {
            //console.log('d', data);
            if (data.ProductId) {
              prev.push(data);
            }
          }
          //console.log('p', prev, selectedRows);
          if (prev.length > rawLength) openSelectedProductDrawer();

          return [...prev];
        });
      }
    }
  }, [addSelectedRows, deselectRows, filteredData, gridRef, selectedRows]);

  const onSelectItem = (item: StringKAnyVPair, sel: boolean) => {
    let i = -1;

    for (let j = selectedRows.length - 1; j >= 0; j--) {
      if (selectedRows[j].ProductId === item.ProductId) {
        i = j;
        break;
      }
    }

    if (sel) {
      if (i < 0) {
        const sr: StringKAnyVPair[] = [ ...selectedRows, item ];
        const sd: StringKAnyVPair = {};

        sr.forEach(e => sd[e.ProductId] = e);
        setSelected(sd);
        setSelectedRows(sr);
        openSelectedProductDrawer();
      }
    } else {
      if (i > -1) {
        const sd: StringKAnyVPair = {};

        selectedRows.splice(i, 1);
        selectedRows.forEach(e => sd[e.ProductId] = e);
        setSelectedRows([ ...selectedRows ]);
        setSelected(sd);
      }
    }
  };

  const onSkipChange = (skip: number) => {
    // console.log('skip -->', skip);
    fetchProductList({ skip });
    setPageSkip(skip);
    //gridRef?.current.deselectAll();
  };

  const onSortChange = async (info: any) => {
    // console.log('-->', info);
  };

  const onViewChange = (evt: any) => {
    setViewMode(evt.target.value);
  };

  const printLineSheetTitle = () => {
    return (<Space>
      {/*<Button
        disabled={searchGroup === GROUP_OPTION_NO_GROUP || selectedRows.length === 0}
        onClick={openPrintRowsDialog}
        size="small"
        type="primary"
      >
        <PrinterOutlined />
        Print
      </Button>*/}
      {selectedRows.length > 0 && <Badge count={selectedRows.length} dot={false} overflowCount={MAX_BADGE_COUNT} />}
    </Space>);
  };

  const renderRowDetails = (param: any) => {
    const { data } = param;
    //console.log('p ->', data, param);
    return <GroupDetail row={data} />;
  };

  const searchStyleVariationOptions = (k: string) => {
    let ret: any[] = [];

    if (Array.isArray(styleVariation[k])) {
      ret = styleVariation[k].map((c: StringKAnyVPair) => ({
        //label: c.description || c.code,
        label: c.code,
        value: c.code,
      }));
    }

    return ret;
  };

  const searchPanelFilters = () => {
    return [
      <>
        <FormLabel>Bundle Type</FormLabel>
        <Select
          allowClear
          onChange={(val) => onExtraFilterFieldChange('bundletype', val as number)}
          options={bundleTypeOptions}
          value={extraBundleType}
        />
      </>,
      <>
        <FormLabel>Category</FormLabel>
        <Select
          allowClear
          onChange={(val) => onExtraFilterFieldChange('categorycode', val)}
          options={searchStyleVariationOptions('categoryCode')}
          mode="multiple"
          value={extraCategoryCode}
        />
      </>,
      <>
        <FormLabel>Subcategory</FormLabel>
        <Select
          allowClear
          onChange={(val) => onExtraFilterFieldChange('subclasscode', val)}
          options={searchStyleVariationOptions('subclassCode')}
          mode="multiple"
          value={extraSubClassCode}
        />
      </>,
      <>
        <FormLabel>Class</FormLabel>
        <Select
          allowClear
          onChange={(val) => onExtraFilterFieldChange('classcode', val)}
          options={searchStyleVariationOptions('classCode')}
          mode="multiple"
          value={extraClassCode}
        />
      </>,
      <>
        <FormLabel>Group</FormLabel>
        <Select
          allowClear
          onChange={(val) => onExtraFilterFieldChange('groupcode', val)}
          options={searchStyleVariationOptions('groupCode')}
          mode="multiple"
          value={extraGroupCode}
        />
      </>,
      <>
        <FormLabel>Subgroup</FormLabel>
        <Select
          allowClear
          onChange={(val) => onExtraFilterFieldChange('subgroupcode', val)}
          options={searchStyleVariationOptions('subgroupCode')}
          mode="multiple"
          value={extraSubGroupCode}
        />
      </>,
      <>
        <FormLabel>Division</FormLabel>
        <Select
          allowClear
          onChange={(val) => onExtraFilterFieldChange('divisioncode', val)}
          options={searchStyleVariationOptions('divisionCode')}
          mode="multiple"
          value={extraDivisionCode}
        />
      </>,
      <>
        <FormLabel>Department</FormLabel>
        <Select
          allowClear
          onChange={(val) => onExtraFilterFieldChange('departmentcode', val)}
          options={searchStyleVariationOptions('departmentCode')}
          mode="multiple"
          value={extraDepartmentCode}
        />
      </>,
      <>
        <FormLabel>Manufacturer</FormLabel>
        <Select
          allowClear
          onChange={(val) => onExtraFilterFieldChange('manufacturer', val)}
          options={searchStyleVariationOptions('manufacturer')}
          mode="multiple"
          value={extraManufacturer}
        />
      </>,
      <>
        <FormLabel>Model</FormLabel>
        <Select
          allowClear
          onChange={(val) => onExtraFilterFieldChange('model', val)}
          options={searchStyleVariationOptions('model')}
          mode="multiple"
          value={extraModel}
        />
      </>,
      <>
        <FormLabel>Alternate</FormLabel>
        <Select
          allowClear
          onChange={(val) => onExtraFilterFieldChange('alternate', val)}
          options={extraAlternateOptions as any[]}
          mode="multiple"
          value={extraAlternateCode}
        />
      </>,
      <>
        <FormLabel>Remark</FormLabel>
        <Input
          allowClear
          onChange={(evt) => onExtraFilterFieldChange('remark', evt.target.value)}
          value={extraRemark}
        />
      </>,
      <>
        <FormLabel>Product Year</FormLabel>
        <Input
          allowClear
          //onChange={(val) => onExtraFilterFieldChange('productyear', val)}
          onChange={(evt) => onExtraFilterFieldChange('productyear', evt.target.value)}
          //options={[{value: '2022'}, {value: '2023'}, {value: '2024'}]}
          value={extraProductYear}
        />
      </>,
      <>
        <FormLabel>Gender</FormLabel>
        <Select
          allowClear
          onChange={(val) => onExtraFilterFieldChange('gender', val)}
          options={searchStyleVariationOptions('gender')}
          mode="multiple"
          value={extraGender}
        />
      </>,
      <>
        <FormLabel>Age group</FormLabel>
        <Select
          allowClear
          onChange={(val) => onExtraFilterFieldChange('agegroup', val)}
          options={searchStyleVariationOptions('ageGroup')}
          mode="multiple"
          value={extraAgeGroup}
        />
      </>,
      <>
        <FormLabel>Country of Origin</FormLabel>
        <Select
          allowClear
          onChange={(val) => onExtraFilterFieldChange('countryoforigin', val)}
          options={searchStyleVariationOptions('countryOfOrigin')}
          mode="multiple"
          value={extraCountryOfOrigin}
        />
      </>,
    ];
  };

  const toggleSelectedProductDrawer = () => {
    setSelectedProductDrawerVisible(!selectedProductDrawerVisible);
  };

  const updateGridRow = (row: Entities.ProductProfile) => {
    let found = false;

    for (let i = 0; i < filteredData.length; i++) {
      if (row.ProductId === filteredData[i].ProductId) {
        filteredData[i] = { ...row };
        found = true;
        break;
      }
    }

    if (found) {
      setFilteredData([...filteredData]);
    }
  };


  useEffect(() => {
    if (!initDataReady) {
      loadControlTowerOrigin()
      loadCopywritingElements();
      loadInitialData();
      setInitDataReady(true);
    }

    getProductListStyle();
  }, [getProductListStyle, initDataReady, loadInitialData, loadControlTowerOrigin]);

  return (
    <>
      <ContentLayout>
        <Heading
          //actions={!selectedProductDrawerVisible ? <Button className="shopping-cart-btn" onClick={openSelectedProductDrawer}><ShoppingCartOutlined />{selectedRows.length > 0 && !selectedProductDrawerVisible && <Badge count={selectedRows.length} overflowCount={MAX_BADGE_COUNT} />}</Button> : null}
          title="Line Sheet"
        />
        <Spacer />
        <PageWrapper flexGrow noPadding transparent>
          <Row className="content-section" justify="space-between">
            <SearchCol>
              <Space direction="vertical" style={{ width: "100%" }}>
                <Row className="search-element-area">
                  <Row>
                    {productListStyle !== "2" && (
                      <Row align="middle" className="display-category-field">
                        <span
                          className="field-label"
                          style={{ paddingRight: 8 }}
                        >
                          Display
                        </span>
                        <Radio.Group
                          onChange={onGroupModeChange}
                          //style={{ width: 430 }}
                          value={searchGroup}
                        >
                          <Radio
                            // disabled={!isStyleCode(searchCodeType)}
                            value={GROUP_OPTION_STYLE}
                          >
                            Style
                          </Radio>
                          {productListStyle !== "1" && (
                            <Radio
                              // disabled={!isCommonGroupOption(searchCodeType)}
                              value={GROUP_OPTION_COLOR}
                            >
                              Substyle
                            </Radio>
                          )}
                          <Radio
                            // disabled={!isCommonGroupOption(searchCodeType)}
                            value={GROUP_OPTION_PRODUCT}
                          >
                            Product
                          </Radio>
                          {/*<Radio
                            // disabled={!isCommonGroupOption(searchCodeType)}
                            value={GROUP_OPTION_NO_GROUP}
                          >
                            All
                          </Radio>*/}
                        </Radio.Group>
                      </Row>
                    )}
                    <Radio.Group
                      buttonStyle="solid"
                      defaultValue={viewMode}
                      onChange={onViewChange}
                    >
                      <Radio.Button value="list">
                        <UnorderedListOutlined />
                      </Radio.Button>
                      <Radio.Button value="grid">
                        <AppstoreOutlined />
                      </Radio.Button>
                    </Radio.Group>
                    <span className="item-spacer" />
                    {isCodeListType(searchCodeType) && (
                      <StyleInputWrapper>
                        <Input
                          addonAfter={addonSearchButton()}
                          addonBefore={searchCodeTypeSelector()}
                          allowClear
                          className={
                            filterPanelVisible ? "code-selector-active" : ""
                          }
                          //disabled
                          //onBlur={onSearchbarBlur}
                          onMouseDown={onSearchbarFocus}
                          onChange={onSearchCodeChange}
                          onMouseEnter={onMouseEnterFilter}
                          onMouseLeave={onMouseLeaveFilter}
                          readOnly
                          ref={codeInputRef}
                          value={searchMultiCode.replace(/\n/g, ", ")}
                        />
                      </StyleInputWrapper>
                    )}
                    {!isCodeListType(searchCodeType) && (
                      <StyleInputWrapper>
                        <Input
                          addonAfter={addonSearchButton()}
                          addonBefore={searchCodeTypeSelector()}
                          allowClear
                          className={
                            filterPanelVisible ? "code-selector-active" : ""
                          }
                          //onBlur={onSearchbarBlur}
                          onMouseDown={onSearchbarFocus}
                          onChange={onSearchCodeChange}
                          onMouseEnter={onMouseEnterFilter}
                          onMouseLeave={onMouseLeaveFilter}
                          ref={codeInputRef}
                        />
                      </StyleInputWrapper>
                    )}
                  </Row>
                  <Space>                    
                    <Button
                      disabled={searchGroup === GROUP_OPTION_NO_GROUP || selectedRows.length === 0}
                      onClick={openPrintRowsDialog}
                      >
                      <PrinterOutlined />
                      Print Line Sheet
                    </Button>
                  </Space>
                </Row>
                <Space style={searchMoreStyle}>
                  <div className="title-wrapper">
                    <span className="field-label">Title:</span>
                    <Input
                      allowClear
                      onChange={onSearchTitleChange}
                      style={{ width: 200 }}
                    />
                  </div>
                  <div className="channel-ctrl-flag-wrapper">
                    <span className="field-label">Sales Channel:</span>
                    <ChannelControlFlagSelector
                      onChange={onSelectChannelControlFlags}
                      style={{ width: 290 }}
                    />
                  </div>
                </Space>
                <Space style={searchMoreStyle}>
                  <div className="labels-wrapper">
                    <span className="field-label">Labels:</span>
                    <LabelsSelector
                      onChange={onSelectLabels}
                      style={{ width: 200 }}
                    />
                  </div>
                  <div className="labels-wrapper">
                    <span className="field-label">Brand:</span>
                    <Input
                      onChange={(e) => setSearchBrand(e.target.value)}
                      style={{ width: 200 }}
                    />
                  </div>
                </Space>
                <Row
                  align="middle"
                  className="search-btn-row"
                  justify="space-between"
                  style={{ marginTop: searchMore ? 0 : -6, overflowX: 'auto' }}
                >
                  {searchBegun && showSearchConditionTags(dataInited)}
                  {searchBegun && <Button className="shopping-cart-btn" onClick={toggleSelectedProductDrawer}><ShoppingCartOutlined />{selectedRows.length > 0 && <Badge count={selectedRows.length} overflowCount={MAX_BADGE_COUNT} />}</Button>}
                </Row>
              </Space>
            </SearchCol>
            {/* <Col>
                <Row align="top" style={{ height: "100%" }}>
                  {filteredData.length > 0 && (
                    <div className="action-btn-wrap">
                      <Button
                        disabled={isFetching}
                        onClick={() => setExportModalVisible(true)}
                      >
                        <CloudDownloadOutlined />
                        Export
                      </Button>
                    </div>
                  )}
                </Row>
              </Col> */}
          </Row>
          {inited ? (
            <Spacer height={14} />
          ) : (
            SearchTips(680, 300, "Input your search criteria", 130)
          )}
          {inited && (
            <Card
              size="small"
              style={{ flexGrow: 1 }}
              bodyStyle={{
                height: "100%",
                marginRight: selectedProductDrawerVisible ? selectedProductDrawerWidth - 12 : undefined,
              }}
            >
              {viewMode === 'list' && <DataGrid
                checkboxColumn
                columns={gridColumns(searchTreeMode)}
                dataSource={dataSource}
                defaultFilterValue={filterValue}
                defaultLimit={DEFAULT_GRID_LIMIT}
                enableFiltering={false}
                filterTypes={gridFilterTypes}
                idProperty="ProductId"
                limit={pageTop}
                loadNode={loadNextLevelProducts}
                // onNodeCollapse={(arg:any) => {
                //   const { node, index} = arg
                //   // node.nodes = null;
                //   const temp = [...filteredData]
                //   temp[index] = {...temp[index], nodes: null}
                //     setFilteredData([...temp])
                // }}
                loading={isFetching}
                onNodeExpand={(arg:any) => {
                  const { index } = arg
                  const temp = [...filteredData]
                  temp[index] = {...temp[index], nodes: null}
                  setFilteredData([...temp])
                }}
                // loadNodeOnce={loadNextLevelProducts}
                onFilterValueChange={onFilterValueChange}
                onLimitChange={onLimitChange}
                onReady={setGridRef}
                onSelectionChange={onSelectionChange}
                onSkipChange={onSkipChange}
                onSortInfoChange={onSortChange}
                pageSizes={[20, 30, 50, 100, 200, 500]}
                pagination
                checkboxOnlyRowSelect
                renderRowDetails={renderRowDetails}
                rowExpandColumn={searchGroupMode}
                rowExpandHeight={300}
                rowHeight={35}
                scrollProps={{ autoHide: false, }}
                // selected={selectedRows}
                selected={selected}
                skip={pageSkip}
                sortable={false}
                style={{ height: "100%" }}
                treeColumn={searchTreeMode ? "mediaURL" : undefined}
              />}
              {viewMode === 'grid' && <GridViewContainer>
                <div className="grid-ctn">
                  {!isFetching && <ProductGridView
                    columns={gridColumns(searchTreeMode)}
                    data={filteredTempData}
                    dataType={searchGroup}
                    onItemClick={openProductDetailDialog}
                    onSelectChange={onSelectItem}
                    selectable
                    selectedIdList={selectedRows.map(e => e.ProductId)}
                  />}
                  {isFetching && <>
                    <Loading size={LOADING_ICON_SIZE1} />
                  </>}
                </div>
                <Row align="middle" className="grid-bottom-toolbar">
                  <Pagination
                    current={(pageSkip / pageTop) + 1}
                    onChange={onPageChange}
                    pageSize={pageTop}
                    pageSizeOptions={['20', '30', '50', '100', '200', '500']}
                    showQuickJumper
                    total={totalCount}
                  />
                </Row>
              </GridViewContainer>}
            </Card>
          )}
          <Drawer
            bodyStyle={{padding: 0}}
            className="selected-product-drawer"
            //closable={false}
            getContainer={false}
            onClose={closeSelectedProductDrawer}
            mask={false}
            //onMouseDown={onPanelMouseDown}
            //onMouseEnter={props.onMouseEnter as any}
            //onMouseLeave={props.onMouseLeave as any}
            //ref={attrCtnRef}
            style={{ position: 'absolute', top: 248, height: 'calc(100% - 248px)' }}
            title={printLineSheetTitle()}
            visible={selectedProductDrawerVisible}
            width={selectedProductDrawerWidth}
          >
            <DrawerContainer>
              <div
                className="width-dragger"
                draggable="true"
                onDragStart={onDragSelectedProductDrawerStart}
                onDragEnd={onDragSelectedProductDrawerEnd}
              />
              <GridDrawerWrapper>
                <ProductGridView
                  columnNum={1}
                  columns={gridColumns(searchTreeMode)}
                  data={selectedRows}
                  dataType={searchGroup}
                  isInDrawer
                  onSelectChange={onSelectItem}
                  setData={setSelectedRows}
                  sortDraggable
                  sortDroppable
                />
              </GridDrawerWrapper>
            </DrawerContainer>
          </Drawer>
        </PageWrapper>
        <SearchFilterPanel
          channelFlagVersion={channelFlagVersion}
          clearChannelFlags={clearChannelFlags}
          clearLabels={clearLabels}
          clearSearchBrand={clearSearchBrand}
          clearSearchTitle={clearSearchTitle}
          excludeSalesChannel={excludeSalesChannel()}
          extraFilter={searchPanelFilters()}
          // extraSorter={searchPanelSorters()}
          handleSearch={handleSearchProducts}
          hidePanel={hideSearchFilterPanel}
          labelVersion={labelVersion}
          onMouseEnter={onMouseEnterFilter}
          onMouseLeave={onMouseLeaveFilter}
          searchBrandVersion={searchBrandVersion}
          searchCodeType={searchCodeType}
          searchMultiCode={searchMultiCode}
          searchTitleVersion={searchTitleVersion}
          searchTagVersion={searchTagVersion}
          searchGroupsVersion={searchGroupsVersion}
          setBrand={setSearchBrand}
          setLabels={setSearchLabels}
          setMultiCode={setSearchMultiCode}
          setSalesChannel={setSearchCCFs}
          setSortType={setSearchSorter}
          setStatus={setSearchStatus}
          setTitle={setSearchTitle}
          showPanel={showSearchFilterPanel}
          status={searchStatus}
          statusOptions={getOperationStatusOptions().map((e) => ({
            label: e.description,
            value: e.code,
          }))}
          x={filterPanelOutline.x || 0}
          y={filterPanelOutline.y || 0}
          visible={filterPanelVisible}
          width={filterPanelOutline.width || 0}
          onGroupChange={(value: any, option: any) =>
            onSelectGroups(value, option)
          }
          groupList={groupList}
          onTagChange={(value: string) => onSelectTags(value || "")}
          clearExtraFilter={() => {
            clearExtraFilters();
            setSearchGroups([]);
            setSearchGroupsStr([]);
            setSearchTags([]);
            setSearchTagVersion(searchTagVersion + 1);
          }}
        />

        {detailVisible && (
          <ProductDetailDialog
            onClose={closeProductDetailDialog}
            product={currentProduct}
            visible={detailVisible}
          />
        )}


        {valueModalVisible && currentValue && (
          <TextEditor
            //productId={currentValue.productId}
            productId={getRealProductId(currentValue.productId)}
            editable={true}
            onClose={() => setValueModalVisible(false)}
            onSave={(newValue: string, overwrite: boolean) => {
              saveBasic(newValue, currentValue, overwrite);
            }}
            readonly={false}
            textContent={currentValue.previewValue}
            title={`${currentValue.previewDisplayName}`}
            visible={true}
          />
        )}
      </ContentLayout>
      {editSelectorDialogVisible && (
        <SimpleSelectorModal
          onClose={closeSimpleSelectorEditor}
          onSave={(newValue: string, overwrite: boolean) => {
            saveBasic(newValue, currentValue, overwrite);
          }}
          options={currentValue.options || []}
          title={`${currentValue.previewDisplayName}`}
          value={currentValue.previewValue}
          visible={editSelectorDialogVisible}
          productId={currentValue.productId}
          productType={currentValue.productType}
        />
      )}
      {editImageDialogVisible && currentValue && (
        <EditImageDialog
          imageUrl={currentValue.previewValue}
          onClose={closeImageEditor}
          //onSave={saveAttributeByEditor}
          onSave={(newValue: string, overwrite: boolean) => {
            saveBasic(newValue, currentValue, overwrite);
          }}
          title={`${currentValue.previewDisplayName}`}
          visible={editImageDialogVisible}
          overwriteEnable={currentValue.productType !==1}
          overwrite={overwriteChild}
        />
      )}
      {printRowsDialogVisible && (
        <PrintRowsDialog
          columns={gridColumns(searchTreeMode)}
          dataType={searchGroup}
          dataSource={selectedRows}
          onClose={closePrintRowsDialog}
          visible={printRowsDialogVisible}
        />
      )}
      {isLoading && (
        <ScreenMask>
          <Loading size={LOADING_ICON_SIZE1} />
        </ScreenMask>
      )}
    </>
  );
};

export const CW_VIEW_ALL = 0;
export const CW_VIEW_CUSTOM = 1;
export const CW_VIEW_GROUP = 2;

export const bundleTypeOptions = [
  {label: 'None', value: 0},
  {label: 'BundleComponent', value: 1},
  {label: 'BundleItem', value: 2},
];

export const getBundleTypeLabel = (typ: number) => {
  let ret = '';

  for (let i = 0; i < bundleTypeOptions.length; i++) {
    const e = bundleTypeOptions[i];

    if (e.value === typ) {
      ret = e.label;
      break;
    }
  }

  return ret;
};

export const renderFilterTag = (
  title: string,
  fields: string | string[],
  onClose?: Function,
) => {
  return (
    <Tag
      closable={!!onClose}
      onClose={onClose ? () => onClose() : undefined}
    >
      <span className="field-label">{title}</span>
      {Array.isArray(fields) ? fields.join(", ") : fields}
    </Tag>
  );
};

export default PageContent;
